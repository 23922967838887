import {
  ComponentProps,
  Heading,
  Image,
  ImageProps,
  type IncrementSizes,
  type SpacerSizes,
} from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  PromotionalCardImageSize,
  type PromotionalCard as PromotionalCardType,
  type Image as ImageType,
} from '@AuroraTypes';
import { BlockContent } from '@Components/LayoutComponents/BlockContent/BlockContent';
import {
  DesignSystemAsset,
  type DesignSystemAssetProps,
} from '@DesignSystemComponents/DesignSystemAsset';

type PromotionalCardProps = PromotionalCardType & ComponentProps;

interface ImageSizeDefinition {
  iconSize: keyof IncrementSizes | (keyof IncrementSizes)[];
  imageProps: (image: ImageType) => {
    containerStyles: SxStyleProp;
    image: Pick<ImageProps, 'width' | 'height'>;
  };
  contentStyles: SxStyleProp;
}

const gaps: (keyof SpacerSizes)[] = ['xs', 's'];

const createImageSizeDefinition = (
  iconSize: keyof IncrementSizes | (keyof IncrementSizes)[],
  imageWidth: [number, number, number],
  imageWidthPercent: number,
): ImageSizeDefinition => {
  return {
    iconSize,
    imageProps: (image: ImageType) => ({
      containerStyles: {
        flex: `0 0 ${imageWidthPercent}%`,
        aspectRatio: image.sourceDimensions!.aspectRatio,
        alignSelf: ['normal', 'flex-start'],
        img: {
          display: ['block', 'initial'],
          width: ['75%', '100%'],
          height: `100%`,
          maxWidth: 'initial',
          maxHeight: 'initial',
        },
      } as SxStyleProp, // theme-ui is not aware of "aspect-ratio" :(
      image: {
        width: imageWidth,
        height: imageWidth.map((w) => w / image.sourceDimensions!.aspectRatio) as [
          number,
          number,
          number,
        ],
      },
    }),
    contentStyles: {
      flex: (theme) =>
        gaps.map((gap) => `0 0 calc(${100 - imageWidthPercent}% - ${theme.space[gap]})`),
    },
  };
};

const imageSizeMap: Record<PromotionalCardImageSize, ImageSizeDefinition> = {
  SMALL: createImageSizeDefinition(['36', '48'], [100, 200, 200], 20),
  MEDIUM: createImageSizeDefinition(['48', '64'], [100, 200, 200], 33),
  LARGE: createImageSizeDefinition(['64', '100'], [200, 400, 400], 50),
};

export const PromotionalCard: React.FC<PromotionalCardProps> = ({
  title,
  content,
  icon,
  image,
  imageSize = 'SMALL',
  className,
}) => {
  const { iconSize, imageProps, contentStyles } = imageSizeMap[imageSize!];

  return (
    <div
      className={className}
      sx={{
        backgroundColor: 'backgroundWhite',
        borderRadius: '12',
        borderColor: 'strokeLightneutral',
        borderStyle: 'solid',
        borderWidth: 'outlinedStrokeWeight',
        overflow: 'hidden',
        position: 'relative',
        padding: ['xs', 'l'],
        columnGap: gaps,
        flexDirection: [image ? 'column' : 'row', 'row'],
        display: 'flex',
      }}
    >
      {icon && (
        <DesignSystemAsset
          name={icon as DesignSystemAssetProps['name']}
          size={iconSize}
        />
      )}

      {image && (
        <div sx={imageProps(image).containerStyles}>
          <Image
            src={image.url}
            alt={image.description}
            fluid={false}
            fit="bounds"
            {...imageProps(image).image}
          />
        </div>
      )}

      <section sx={contentStyles}>
        {title && (
          <Heading
            as="h3"
            variant="medium"
            sx={
              {
                textWrap: 'balance',
              } as any
            } // theme-ui is not aware of "text-wrap" :(
          >
            {title}
          </Heading>
        )}

        {content && (
          <BlockContent
            content={content}
            type="promotional-card-content"
            sx={{
              '> p': {
                marginBottom: 0,
              },
            }}
          />
        )}
      </section>
    </div>
  );
};
